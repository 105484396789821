<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        <div class="mr-auto p-2 bd-highlight">
          Comissão
        </div>
        <v-btn
          color="info"
          class="p-2 bd-highlight"
          @click="openModalAddCommission()"
        >
          Adicionar
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data_table"
        :items-per-page="pageOptions.itemsPerPage"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
        hide-default-footer
        :page.sync="pageOptions.page"
        @page-count="pageCount = $event"
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === loadingSpinner ? false : true"
            medium
            class="me-2"
            @click="getItemCommissionById(item.id)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>

          <v-progress-circular
            v-if="item.id === loadingSpinner ? true : false"
            indeterminate
            :size="25"
            color="gray"
          >
          </v-progress-circular>
        </template>
      </v-data-table>

      <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="openModal"
      width="500"
      persistent
    >
      <modal-commission
        :commission-data="dataToModalById"
        :close-modal="closeModal"
        :updated-table="updatedTable"
        :mode="mode"
        :open-modal="openModal"
        @close="openModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatDate from '@/plugins/mixin'
import { mdiPencilOutline } from '@mdi/js'
import modalCommission from './modalCommission.vue'

export default {
  components: {
    modalCommission,
  },

  mixins: [formatDate],

  data() {
    return {
      mode: '',
      data_table: [],
      dataToModalById: [],
      headers: [
        { text: 'COMISSÃO', value: 'description' },
        { text: 'ATÉ 9', value: 'critical_level' },
        { text: 'MENOR META', value: 'below_goal' },
        { text: 'ATINGIR META', value: 'goal' },
        { text: 'CARTA', value: 'credit_letter', align: 'center' },
        { text: 'NUMERO CRITICO', value: 'number_critical', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },
      ],
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      loading: true,
      icons: {
        mdiPencilOutline,
      },
      openModal: false,
      loadingSpinner: '',
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      await axiosIns.get('api/v1/integration/cnh_commission_standard/index')
        .then(res => {
          this.data_table = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
    },

    async getItemCommissionById(commissionId) {
      this.loadingSpinner = commissionId
      await axiosIns.get(`api/v1/integration/cnh_commission_standard/edit/${commissionId}`)
        .then(res => {
          this.dataToModalById = res.data
        })
      this.loadingSpinner = ''
      this.openModal = true
      this.mode = 'edit'
    },

    closeModal() {
      this.openModal = false
    },

    async nextPage() {
      this.loading = true
      this.clientList = []
      await axiosIns.get(`api/v1/integration/cnh_commission_standard/index?page=${this.pageOptions.page}`)
        .then(res => {
          this.data_table = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
    },

    openModalAddCommission() {
      this.mode = 'add'
      this.openModal = true
    },

    async updatedTable() {
      await this.getItemsTable()
    },

  },
}
</script>
