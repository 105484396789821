<template>
  <v-card>
    <v-card-title>
      {{ mode === 'edit' ? 'Editar' : 'Adicionar' }}
      <v-spacer />
      <v-btn
        icon
        color="error"
      >
        <v-icon
          color="error"
          ml-5
          @click="$emit('close'), clearModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-container v-if="mode === 'edit'">
      <v-card outlined>
        <v-col>
          <v-text-field
            v-model="commissionData.data.description"
            label="Descrição"
            outlined
            dense
          />
          <v-text-field
            v-model="commissionData.data.critical_level"
            label="Até 9"
            outlined
            dense
          />
          <v-text-field
            v-model="commissionData.data.below_goal"
            label="Menor meta"
            outlined
            dense
          />
          <v-text-field
            v-model="commissionData.data.goal"
            label="Atingir meta"
            outlined
            dense
          />
          <v-text-field
            v-model="commissionData.data.credit_letter"
            label="Carta"
            outlined
            dense
          />
          <v-text-field
            v-model="commissionData.data.number_critical"
            label="Numero crítico"
            outlined
            dense
          />
        </v-col>
      </v-card>

      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="updateCommissionById()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>

    <v-container v-else>
      <v-card outlined>
        <v-col>
          <v-text-field
            v-model="addDataCommission.description"
            label="Descrição"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataCommission.critical_level"
            label="Até 9"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataCommission.below_goal"
            label="Menor meta"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataCommission.goal"
            label="Atingir meta"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataCommission.credit_letter"
            label="Carta"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataCommission.number_critical"
            label="Numero crítico"
            outlined
            dense
          />
        </v-col>
      </v-card>

      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="addCommission()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    commissionData: {
      type: Object,
      require: true,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      addDataCommission: {
        description: '',
        critical_level: '',
        below_goal: '',
        goal: '',
        credit_letter: '',
        number_critical: '',
      },
      icons: {
        mdiClose,
      },
    }
  },

  // created() {
  //   this.modeModal = this.mode
  // },
  methods: {
    async updateCommissionById() {
      await axiosIns.put(`api/v1/integration/cnh_commission_standard/update/${this.commissionData.data.id}`, this.commissionData.data)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.updatedTable()
          this.closeModal()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados!',
            showConfirmButton: false,
            text: error,
          })
          this.closeModal()
        })
    },

    async addCommission() {
      const dataCommission = {
        description: this.addDataCommission.description,
        critical_level: this.addDataCommission.critical_level,
        below_goal: this.addDataCommission.below_goal,
        goal: this.addDataCommission.goal,
        credit_letter: this.addDataCommission.credit_letter,
        number_critical: this.addDataCommission.number_critical,
      }

      await axiosIns.post('api/v1/integration/cnh_commission_standard/store/', dataCommission)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão salvos!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.addDataCommission = {
            description: '',
            critical_level: '',
            below_goal: '',
            goal: '',
            credit_letter: '',
            number_critical: '',
          }

          this.updatedTable()
          this.closeModal()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao adicionar dados!',
            showConfirmButton: false,
            text: error,
          })
          this.closeModal()
        })
    },

    clearModal() {
      this.addDataCommission = {
        description: '',
        critical_level: '',
        below_goal: '',
        goal: '',
        credit_letter: '',
        number_critical: '',
      }
    },
  },
}
</script>
